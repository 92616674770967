import { mobileCheck } from "./functions/mobile-check";
console.log(mobileCheck())

// бургер-меню
import { burger } from './functions/burger';

// модальное окно
import GraphModal from 'graph-modal';
const modal = new GraphModal();

import { validateForms } from './functions/validate-forms';
const rules = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
];
const rules1 = [
  {
    ruleSelector: '.input-name',
    rules: [
      {
        rule: 'minLength',
        value: 3
      },
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните имя!'
      }
    ]
  },
  {
    ruleSelector: '.input-tel',
    tel: true,
    telError: 'Введите корректный телефон',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Заполните телефон!'
      }
    ]
  },
  {
    ruleSelector: '.input-checkbox',
    telError: 'Необходимо подтвердить',
    rules: [
      {
        rule: 'required',
        value: true,
        errorMessage: 'Необходимо подтвердить'
      }
    ]
  },
];

const afterForm = () => {
  console.log('Произошла отправка формы');
};

let myform = document.querySelector('.form');
  if (myform) {
  validateForms('.form', rules, afterForm);
  }

let myform1 = document.querySelector('.form-order');
  if (myform1) {
  validateForms('.form-order', rules1, afterForm);
  }
