import _vars from '../_vars';

import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
new Swiper('.swiper', {
  slidesPerView: 'auto',
});

/// hangars-slider

const slider = document.querySelector('.swiper-hangars');

let mySwiper;

function mobileSlider() {
  if (window.innerWidth <= 600 && slider.dataset.mobile == 'false') {
    mySwiper = new Swiper(slider, {
      slidesPerView: 'auto',
      spaceBetween: 10,
      loop: true,
      slideClass: 'swiper-slide',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    });

    slider.dataset.mobile = 'true';
  }

  if (window.innerWidth > 600) {
    slider.dataset.mobile = 'false';
    if (slider.classList.contains('swiper-initialized')) {
      mySwiper.destroy();
    }
  }
}

// mobileSlider()

window.addEventListener('resize', () => {
  mobileSlider();
  }
);


/// typical-slider

const slider1 = document.querySelector('.swiper-typical');

let mySwiper1;

function mobileSliderTypical() {
  if (window.innerWidth <= 600 && slider1.dataset.mobile == 'false') {
    mySwiper1 = new Swiper(slider1, {
      slidesPerView: 'auto',
      spaceBetween: 10,
      loop: true,
      slideClass: 'swiper-slide',
      pagination: {
        el: '.swiper-pagination ',
        clickable: true,
      },
    });

    slider1.dataset.mobile = 'true';
  }

  if (window.innerWidth > 600) {
    slider1.dataset.mobile = 'false';
    if (slider1.classList.contains('swiper-initialized')) {
      mySwiper1.destroy();
    }
  }
}

window.addEventListener('resize', () => {
  mobileSliderTypical();
  }
);
